import { Link } from 'gatsby';
import React from 'react';
import avatar from '#/assets/avatar.jpg';

export default function PageHome(props) {
  const {
    data: {
      recentPosts,
      recentEssays,
    },
  } = props;
  return (
    <main className="home-page">
      <section className="tw-text-center">
        <picture className="tw-inline-block">
          <img src={avatar} className="tw-w-48 tw-rounded-full" />
        </picture>
        <h1>Gerald Liu</h1>
      </section>
      <section>
        <h3>现状</h3>
        <ul>
          <li>Web 前端工程师，深陷业务无法自拔。</li>
          <li>擅长 JavaScript，喜欢 Python。</li>
          <li>工作用 React，但是我更喜欢 Vue 和 Svelte。</li>
          <li>热爱开源社区，<Link to="/portfolio/">这里</Link>有我的一些开源作品。</li>
          <li>希望和志同道合的朋友们一起做有意思的事情~</li>
        </ul>
      </section>
      <section>
        <h3>近期文章</h3>
        <ul>
          {recentPosts.edges.map((edge, i) => (
            <li key={i}>
              <Link to={edge.node.fields.slug}>{edge.node.frontmatter.title}</Link>
            </li>
          ))}
          <li>
            <Link to="/posts/">查看更多...</Link>
          </li>
        </ul>
      </section>
      <section>
        <h3>近期扯淡</h3>
        <ul>
          {recentEssays.edges.map((edge, i) => (
            <li key={i}>
              <Link to={edge.node.fields.slug}>{edge.node.frontmatter.title}</Link>
            </li>
          ))}
          <li>
            <Link to="/essays/">查看更多...</Link>
          </li>
        </ul>
      </section>
    </main>
  );
}
