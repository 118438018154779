import { graphql } from 'gatsby';
import React from 'react';

import Layout from '#/components/layout';
import PageHome from '#/components/page-home';

export default function Index(props) {
  const { data } = props;
  return (
    <Layout>
      <PageHome data={data} />
    </Layout>
  );
}

export const pageQuery = graphql`
  query IndexQuery {
    recentPosts: allMarkdownRemark(
      limit: 3
      filter: {
        fields: {
          type: { eq: "posts" }
          draft: { ne: true }
        }
      }
      sort: { order: DESC, fields: [frontmatter___date] }
    ) {
      edges {
        node {
          fields {
            slug
          }
          frontmatter {
            title
          }
        }
      }
    }
    recentEssays: allMarkdownRemark(
      limit: 2
      filter: {
        fields: {
          type: { eq: "essays" }
          draft: { ne: true }
        }
      }
      sort: { order: DESC, fields: [frontmatter___date] }
    ) {
      edges {
        node {
          fields {
            slug
          }
          frontmatter {
            title
          }
        }
      }
    }
  }
`;
